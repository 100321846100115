import { Mode } from 'src/app/assessment/assessment.model';
import { getEnumKeyFromValue } from 'src/app/shared/util';

export class ModeDefinitions {
  //TODO: Change all enums to actually contain the value eg 'SLAVES' not 'Modern Slavery' (Just need to check if we are still using enum's value "Modern Slavery" to display in the UI or not. We used to do so with the enum value, and may possibly be still doing so)
  static modes = Object.keys(Mode) as (keyof typeof Mode)[];

  static mainModes = [
    getEnumKeyFromValue(Mode, Mode.SLAVES),
    getEnumKeyFromValue(Mode, Mode.GHG_EMISSIONS),
    getEnumKeyFromValue(Mode, Mode.BIODIVERSITY),
  ];

  static isMainMode(mode: keyof typeof Mode) {
    return this.mainModes.includes(mode);
  }
}
